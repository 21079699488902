import React from 'react'
import Img from 'gatsby-image'

const ImageBlockContent = ({ lead, body, listHeading, imageList }) => {
  if (!lead && !body.html && !listHeading && imageList.length === 0) return null

  return (
    <div className="flex flex-wrap lg:mb-20 lg:-ml-12">
      <div className="w-full lg:w-6/12 lg:pl-12 pb-12 lg:pb-0 font-medium lg:pt-8">
        {lead && (
          <h3 className="text-xl lg:text-3xl leading-tight mb-6">{lead}</h3>
        )}
        {body.html && (
          <div
            className="rte mb-8"
            dangerouslySetInnerHTML={{ __html: body.html }}
          />
        )}
      </div>
      <div className="hidden lg:block lg:w-1/12 lg:pl-12 lg:pt-8"></div>
      <div className="lg:w-5/12 lg:pl-12">
        <div className="sticky top-0 left-0 pt-8 mb-8 lg:-mb-5">
          {listHeading && (
            <h3 className="text-xl lg:text-2xl font-bold leading-tight mb-8">
              {listHeading}
            </h3>
          )}
          {imageList && (
            <>
              {imageList
                .filter(item => item.image !== null)
                .map((item, index) => {
                  const fluidImage =
                    item.image.localFile && item.image.localFile.childImageSharp
                      ? item.image.localFile.childImageSharp.fluid
                      : null
                  return (
                    <div
                      key={index}
                      className="flex flex-col xl:flex-row pb-12"
                    >
                      <div
                        className="w-full xl:w-2/5 mr-5"
                        style={{ aspectRatio: '3/2' }}
                      >
                        {fluidImage && (
                          <Img
                            className="w-full h-full object-cover"
                            fluid={fluidImage}
                            alt={item.image.alt}
                          />
                        )}
                      </div>
                      <div className="w-full xl:w-3/5 pt-6 xl:pt-0">
                        {item.image_heading && (
                          <h4 className="text-xl lg:text-2xl font-bold leading-tight">
                            {item.image_heading}
                          </h4>
                        )}
                        {item.image_body.html && (
                          <div
                            className="rte"
                            dangerouslySetInnerHTML={{
                              __html: item.image_body.html,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageBlockContent
