import React from 'react'

import { QuoteIcon } from '../SVG/QuoteIcon'

const Quote = ({ quote }) => {
  if (!quote) return null

  return (
    <>
      {quote.map(
        (quote, index) =>
          quote.quote_heading && (
            <div key={index} className="mb-12 lg:mb-24">
              <div className="flex flex-wrap lg:-ml-12">
                <div className="w-full lg:w-4/12 lg:pl-12 lg:pt-8">
                  <h3 className="heading-xl font-bold mb-8 lg:mb-0 lg:max-w-xs">
                    {quote.quote_heading}
                  </h3>
                </div>
                <blockquote className="w-full lg:w-8/12 lg:pl-12 lg:pt-8">
                  <div className="border border-blue-10 p-8">
                    <div className="w-7 h-5 mb-11">
                      <QuoteIcon />
                    </div>
                    <p className="text-xl font-medium leading-tight">
                      {quote.quote_body.text}
                    </p>
                    <p className="mt-4 font-medium">{quote.quote_name}</p>
                  </div>
                </blockquote>
              </div>
              <hr className="mt-12 lg:mt-24 border-blue-10"></hr>
            </div>
          )
      )}
    </>
  )
}

export default Quote
