import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Service/Hero'
import Content from '../components/Service/Content'
import Intro from '../components/Service/Intro'
import FeatureCard from '../components/Card/Feature'
import CTA from '../components/Service/CTA'
import SEO from '../components/SEO'
import ListBlockContent from '../components/Service/ListBlockContent'
import ImageBlockContent from '../components/Service/ImageBlockContent'
import Quote from '../components/Service/Quote'

class ServiceTemplate extends React.Component {
  render() {
    const { data, location } = this.props
    const post = data.allPrismicServices.edges[0].node.data
    const mobileFeature = data.mobileFeature.edges[0].node.data

    const serviceSchema = {
      '@context': 'https://schema.org',
      '@type': 'Service',
      serviceType: post.service,
      provider: {
        '@type': 'Organization',
        name: 'Cobalt Systems',
        url: 'https://www.cobalt.systems/',
      },
      areaServed: {
        '@type': 'Place',
        name: 'Christchurch, Canterbury',
      },
    }

    return (
      <Layout location={location}>
        <SEO
          title={post.meta_title ? post.meta_title : post.heading + ' Services'}
          type="article"
          description={
            post.meta_description ? post.meta_description : post.lead.text
          }
          location={location}
          schemas={[serviceSchema]}
        />
        <div className="wrapper wrapper--y">
          {post.heading && (
            <h1 className="heading-3xl mb-8 lg:mb-6">{post.heading}</h1>
          )}
          <Hero
            lead={post.lead}
            body={post.body}
            listHeading={post.list_heading}
            list={post.list}
            imageList={post.image_list_block}
          />
          <ListBlockContent
            heading={post.list_block_heading}
            body={post.list_block_body}
            listHeading={post.list_items_heading}
            list={post.list_block}
            backgroundColour={post.list_background_colour}
          />
          <Content content={post.content} />
          <ImageBlockContent
            lead={post.image_block_heading}
            body={post.image_block_body}
            imageList={post.image_block}
          />
          <Quote quote={post.quote_block} />
          {post.heading && (
            <h2 className="heading-xl mb-12">{post.heading} Services</h2>
          )}
          <Intro data={post} />
          {post.featured_case_study.document !== null && (
            <FeatureCard
              data={post}
              mobileFeature={mobileFeature}
              position="bottom"
              inset={true}
            />
          )}
        </div>

        <CTA offset={post.featured_case_study.document !== null} />
      </Layout>
    )
  }
}

ServiceTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const postQuery = graphql`
  query Posts($uid: String!) {
    allPrismicServices(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            meta_title
            meta_description
            heading
            icon {
              alt
              url
            }
            lead
            body {
              html
            }
            list_heading
            list {
              list_item
            }
            image_list_block {
              image_heading
              image_subheading
              image_body {
                html
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            content {
              content_heading
              content_body {
                html
              }
              heading_size
              heading_position
              background_colour
            }
            list_block_heading
            list_background_colour
            list_block_body {
              html
            }
            list_block {
              list_item
            }
            list_items_heading
            quote_block {
              quote_heading
              quote_name
              quote_body {
                text
              }
            }
            image_block_heading
            image_block_body {
              html
            }
            image_block {
              image_heading
              image_body {
                html
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            services {
              service_item {
                document {
                  ... on PrismicServicesItems {
                    uid
                    data {
                      title
                      icon {
                        url
                        alt
                      }
                      category {
                        uid
                      }
                    }
                  }
                }
              }
            }
            meta_title
            meta_description
            service
            featured_case_study {
              uid
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    heading
                    hero {
                      alt
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1317, maxHeight: 570) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            featured_case_study_text {
              text
              html
            }
          }
        }
      }
    }
    mobileFeature: allPrismicServices(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            meta_title
            meta_description
            featured_case_study {
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    hero {
                      alt
                      copyright
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 640, maxHeight: 680) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServiceTemplate
