import React from 'react'

import Logomark from '../SVG/Logomark'

const ListBlockContent = ({
  heading,
  body,
  list,
  listHeading,
  backgroundColour,
}) => {
  if (!heading && !body.html && list.length === 0 && !listHeading) return null

  return (
    <div className="flex flex-wrap mb-12 lg:mb-20 lg:-ml-12">
      <div className="w-full lg:w-6/12 lg:pl-12 font-medium lg:pt-8">
        {heading && (
          <h3 className="text-2xl lg:text-5xl leading-tight mb-6 font-bold">
            {heading}
          </h3>
        )}
        {body.html && (
          <div
            className="rte mb-8"
            dangerouslySetInnerHTML={{ __html: body.html }}
          />
        )}
      </div>
      <div className="hidden lg:block lg:w-1/12 lg:pl-12 lg:pt-8"></div>
      <div className="w-full lg:w-5/12 lg:pl-12 lg:pt-8">
        <div
          className={`p-8 ${
            backgroundColour === 'Light Blue' ? 'bg-blue-10' : ''
          }`}
        >
          {listHeading && (
            <h3 className="text-xl lg:text-2xl font-bold leading-tight mb-8">
              {listHeading}
            </h3>
          )}
          {list && (
            <ul className="font-semibold lg:text-lg -mb-5">
              {list
                .filter(item => item.list_item !== null)
                .map((item, index) => {
                  return (
                    <li key={index} className="pb-5 flex items-start">
                      <Logomark className="w-4 lg:w-5 mr-4 lg:mr-6 flex-shrink-0" />
                      {item.list_item}
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListBlockContent
