import React from 'react'

export const QuoteIcon = () => {
  return (
    <svg className="w-full h-full" fill="none" viewBox="0 0 29 22">
      <path
        fill="currentColor"
        d="M11.905 15.8c0 1.7-.6 3.1-1.8 4.2-1.2 1.2-2.6 1.7-4.2 1.7-1.7 0-3.1-.5-4.2-1.7-1.2-1.1-1.7-2.5-1.7-4.2 0-1 .2-2.1.8-3.5L5.505 0h6.1l-3.3 10.4c2.4 1.1 3.6 2.9 3.6 5.4Zm16.3 0c0 1.7-.6 3.1-1.8 4.2-1.2 1.2-2.6 1.7-4.2 1.7-1.7 0-3.1-.5-4.2-1.7-1.2-1.1-1.7-2.5-1.7-4.2 0-1 .2-2.1.8-3.5l4.7-12.3h6.1l-3.3 10.4c2.4 1.1 3.6 2.9 3.6 5.4Z"
      />
    </svg>
  )
}
